import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       Greenfields Community Church
       <br></br>
       (SITE UNDER MAINTENANCE)
      </header>
    </div>
  );
}

export default App;
